/* 100 */
/* 200 light */
/* 300 semilight */
/* 400 normal */
/* 500 */
/* 600 semibold */
/* 700 bold */
/* 800 extrabold */
/* 900 black */

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 100;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 200;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 600;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: bold;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 100;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 200;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 300;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: normal;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 500;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: 600;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Mono';
    font-style: italic;
    font-weight: bold;
    src: local('Roboto Mono'),
    url('../public/fonts/RobotoMono/static/RobotoMono-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Bungee';
    font-weight: 400;
    src: local('Bungee'),
    url('../public/fonts/Bungee/Bungee-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Shrikhand';
    font-weight: 400;
    src: local('Shrikhand'),
    url('../public/fonts/Shrikhand/Shrikhand-Regular.ttf') format('truetype');
}

html, body, #root {
    margin: 0 auto;
    background: #FF38AB;
    overflow-x: clip;
    overflow-y: auto;
}

body {
    font-family: "Roboto Mono", monospace;
    font-style: normal;
    font-weight: normal;
    color: white;
    font-size: 18px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

#root {
    margin: 0 auto;
    background: linear-gradient(#462B69, #221D2B);
}

button {
    font-size: 18px;
}

.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    background-color: black;
}

.content-wrapper-padding {
    padding-left: 24px;
    padding-right: 24px;
}

.section {
    margin-top: 184px;
    margin-bottom: 184px;
    background-color: transparent;
    position: relative;
    overflow: visible;
}

a {
    color: #FF38AB;
    text-decoration: none;
}

a:hover {
    color: #FF38AB;
    text-decoration: underline;
}

h1 {
    font-family: "Bungee";
    font-style: normal;
    font-weight: normal;
    font-size: 96px;
    line-height: 115px;
    color: #F0C21D;
    margin-top: 20px;
    margin-bottom: 30px;
    text-shadow: 6px 6px #DD0582;
}

h2 {
    font-family: 'Shrikhand';
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 55px;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
}

h1 a {
    text-decoration: none;
    color: #F0C21D;
}

h1 a:hover {
    padding-left: 2px;
    padding-top: 2px;
    text-shadow: 4px 4px #DD0582;
    text-decoration: none;
    color: #F0C21D;
}

h3 {
    font-family: "Bungee";
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 46px;
    color: #FF38AB;
}

a h3 { text-decoration: none; }
a:hover h3 { text-shadow: 3px 3px #F0C21D; }

.color-palette-0 { color: #F0421D }
.color-palette-1 { color: #F08D1D }
.color-palette-2 { color: #F0C21D }
.color-palette-3 { color: #FFFFFF }
.color-palette-4 { color: #78D63A }
.color-palette-5 { color: #4E80E9 }
.color-palette-6 { color: #10B0A8 }
.color-palette-7 { color: #F0328E }


@media only screen and (max-width: 600px) {

    h1 {
        text-shadow: 5px 5px #DD0582;
        font-size: 64px;
        line-height: 76px;
    }

    h2 {
        font-size: 28px;
        line-height: 40px;
    }

    .content-wrapper-padding {
        padding-left: 12px;
        padding-right: 12px;
    }

    .section {
        margin-top: 48px;
        margin-bottom: 48px;
    }
    .section:first-of-type { margin-top: 48px; }
    .section:last-of-type { margin-bottom: 48px; }
}