.team-members-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    align-content: center;
    text-align: left;
    margin: 0 auto;
}

.team-members-wrapper a {
    text-decoration: none;
}

.team-member {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 24px;
}

.team-member .svg, .team-member a {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}

.team-member ul {
    padding-left: 20px;
}

@media only screen and (max-width: 900px) {
    .team-members-wrapper { justify-content: space-around; }
}