.wallet-section {
    background-color: transparent;
    overflow: visible;
    position: relative;
}

.wallet-section-img {
    position: absolute;
    left: -11%;
    top: -3%;
    z-index: 2;
}

.wallet-section .quotes {
    position: absolute;
    right: 24px;
    top: -4%;
    z-index: 20;
}

.wallet-section .quotes li {
    text-align: right;
}

.wallet-section-body {
    position: relative;
    padding-top: 55%;
    max-width: 55%;
    z-index: 20;
}

.wallet-section-body h2 {
    display: inline;
}

.wallet-section-body .color-palette-7 {
    color: #FF38AB;
}

.wallet-section-meme {
    position: absolute;
    right: -5%;
    bottom: 4%;
    width: 100%;
    max-width: 620px;
    max-height: 620px;
    z-index: 5;
}

#wallet-section-appstore {
    max-width: 80%;
    margin: 0 auto;
    margin-left: 1em;
    margin-top: -1em;
    z-index: 1000;
    display: block;
}

#wallet-section-appstore img {
    max-width: 100%;
    width: 100%;
}

#wallet-section-img-meme {
    max-width: 100%;
    margin-top: -3%;
}

@media only screen and (max-width: 1233px) {
    .wallet-section-meme {
        max-width: 50%;
        bottom: 7%;
    }
    #wallet-section-img-meme { margin-top: 5%; }
}

@media only screen and (max-width: 1150px) {
    .wallet-section .quotes h2 {
        font-size: 28px;
        line-height: 40px;
    }

    .wallet-section .quotes { top: 0; }

    .wallet-section-img {
        max-width: 130%;
        left: -10%;
    }
}

@media only screen and (max-width: 1024px) {
    .wallet-section-meme { bottom: 15%; }
    #wallet-section-img-meme { margin-top: 30%;}
}

@media only screen and (max-width: 900px) {
    .wallet-section .quotes {
        position: relative;
        margin: 0;
        right: unset;
        top: unset;
        margin-top: 45px;
    }

    .wallet-section .quotes h2 {
        text-align: left;
        font-size: 38px;
        line-height: 55px;
    }

    .wallet-section-img {
        max-width: 130%;
        left: -10%;
        top: 16%;
        z-index: 25;
    }

    .wallet-section-body {
        max-width: unset;
        padding-top: 70%;
        z-index: 15;
    }

    .wallet-section-meme {
        position: relative;
        width: 100%;
        max-width: 100%;
        max-height: unset;
        margin: 0 auto;
        right: 0;
        margin-top: 2em;
    }

    #wallet-section-appstore {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    #wallet-section-img-meme {
        display: block;
        max-width: 80%;
        width: 100%;
        margin: 0 auto;
        top: 0;
        bottom: 0;
        margin-top: 2em;
        margin-bottom: -4em;
    }
}

@media only screen and (max-width: 600px) {
    .wallet-section .quotes h2 { font-size: 28px; line-height: 40px; }
    .wallet-section-img {
        max-width: 132%;
        top: 12.5%;
    }
    #wallet-section-img-meme { max-width: 100%;}
}

@media only screen and (max-width: 450px) {
    .wallet-section-img { top: 14%; }
}

@media only screen and (max-width: 400px) {
    .wallet-section-img { top: 15%; }
}

@media only screen and (max-width: 380px) {
    .wallet-section-img { top: 16.25%; }
}