.cta-section {
    margin-top: 96px;
    margin-bottom: 48px;
}

.cta-section-buttons {
    display: flex;
    background-color: transparent;
    justify-content: start;
    gap: 2em;
}

.cta-section-item {
    color: white;
    background-color: transparent;
    box-shadow: 0.5em 0.5em white;
    border: solid 1px white;
    padding: 0em 1em;
    display: flex;
    gap: 1em;
    justify-content: space-between;
}

.cta-section-item-container {
    margin: 0.25em;
    flex: 1;
}

.cta-section-item-container:hover {
    margin-top: 0.5em;
    margin-left: 0.5em;
    margin-bottom: 0em;
    margin-right: 0em;
}

.cta-section-item:hover {
    box-shadow: 0.25em 0.25em white;
}

.cta-section a h3 {
    color: white;
    text-decoration: none;
    line-height: 0;
}

.cta-section a:hover h3 {
    text-shadow: unset;
}

.cta-section a:hover {
    text-decoration: none;
}

#cta-section-headline {
    display: block;
    font-size: 62px;
    line-height: 86px;
}

#cta-section-nyknyc {
    display: block;
    font-family: "Bungee";
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 46px;
    flex: 1;
}

@media only screen and (max-width: 900px) {
    .cta-section-buttons { flex-direction: column; }
}

@media only screen and (max-width: 1257px) {
    #cta-section-nyknyc { font-size: 28px; line-height: 33px; }
}

@media only screen and (max-width: 1103px) {
    #cta-section-nyknyc { display: none; }
}