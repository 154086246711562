.roadmap-container {
    position: relative;
    background-color: transparent;
    overflow: visible;
    z-index: 10;
    overflow: visible;
}

.roadmap-section {
    position: relative;
    overflow: visible;
    background-color: transparent;
    margin-top: 204px;
    flex: 1;
}

.roadmap-normal {
    position: relative;
    display: flex;
    gap: 3%;
    margin-top: 5%;
}

.roadmap-card {
    position: relative;
    background-color: #472973;
    border: solid 1px #E73795;
    box-shadow: 44px -30px #2D174D;
    z-index: 30;
    margin-bottom: 72px;
    flex: 1;
    padding: 2.8%;
}

.roadmap-card:first-of-type {
    z-index: 40;
    margin-top: 72px;
    margin-bottom: 0px;
}

.roadmap-card:last-of-type {
    z-index: 20;
    margin-top: -72px;
    margin-bottom: 144px;
}

.roadmap-card h2 {
    font-family: "Bungee";
    font-style: normal;
    font-weight: normal;
    font-size: 54px;
    line-height: 64px;
    color: #F0C21D;
}

.roadmap-lines {
    position: absolute;
    width: 100%;
    height: 140%;
    margin-top: -245px;
    background-image: url('/public/images/roadmap-lines.png');
    background-image: -webkit-image-set(
            url('/public/images/roadmap-lines.png') 1x,
            url('/public/images/roadmap-lines@2x.png') 2x); /* Temporary fallback for Chrome and Safari browsers until they support 'image-set()' better */
    background-image: image-set(
            url('/public/images/roadmap-lines.png') 1x,
            url('/public/images/roadmap-lines@2x.png') 2x);
    background-position: top center;
    background-repeat: no-repeat;
    z-index: 5;
}

.roadmap-card-meme {
    position: absolute;
    display: block;
    right: 5%;
    bottom: 5%;
    max-width: 50%;
}

.roadmap-card-lines {
    display: block;
    margin-bottom: 5%;
}

.roadmap-section a {
    position: relative;
    text-decoration: none;
    z-index: 20;
}

.roadmap-section li a {
    color: white;
}

.roadmap-section li a:hover {
    text-decoration: underline;
}

.road-map-wips {
    text-align: right;
    float: right;
    max-width: 50%;
    display: block;
    position: relative;
}

.roadmap-section a h3 {
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 1134px) {
    .road-map-wips { max-width: 70%; }
}

@media only screen and (max-width: 900px) {
    .roadmap-normal { flex-direction: column; row-gap: 64px; }
    .roadmap-card:first-of-type { z-index: 15; margin: unset; }
    .roadmap-card:last-of-type { z-index: 30; margin: unset; }
    .roadmap-card { margin: unset; padding: 5% }
    .roadmap-lines {
        height: 100%;
        margin-top: -28vw;
        background-size: unset;
    }
    .roadmap-card-meme {
        position: relative;
        margin: 0 auto;
        max-width: 50%;
    }

    .road-map-wips {
        float: unset;
        max-width: 100%;
        text-align: left;
        margin-top: 48px;
    }
}

@media only screen and (max-width: 800px) {
    .roadmap-lines { margin-top: -31vw; }
}

@media only screen and (max-width: 700px) {
    .roadmap-lines { margin-top: -45vw; }
}

@media only screen and (max-width: 600px) {
    .roadmap-lines { margin-top: -45vw; }
    .roadmap-container h1 {
        margin-top: -10vw;
        margin-bottom: 14%;
    }
}

@media only screen and (max-width: 500px) {
    .roadmap-lines { margin-top: -60vw; }
}

@media only screen and (max-width: 400px) {
    .roadmap-lines { margin-top: -67vw; }
}

@media only screen and (max-width: 375px) {
    .roadmap-lines { margin-top: -71vw; }
}
